<template>
  <div class="home">
    <div class="container-fluid">
      <div class="row">

        <nav class="navbar sticky-top navbar-light bg-light d-block d-md-none">
          <!-- navbar-expand-lg -->
          <div class="container-fluid justify-content-center">
            <a class="navbar-brand title" href="#">Sarah & Nathan</a>
          </div>
        </nav>
        
        <div class="sn-intro col-12 col-md-8 px-0">
          <div id="carouselFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="caption d-none d-md-block">
                <h1 class="title">Sarah & Nathan</h1>
                <h2 class="subtitle">We can’t wait to share our special day with you.</h2>
              </div>

              <div class="carousel-item active">
                <img src="@/assets/175A7233@2x.jpg" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="@/assets/175A7111@2x.jpg" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="@/assets/175A7079@2x.jpg" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="@/assets/175A7066@2x.jpg" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                <img src="@/assets/175A7061@2x.jpg" class="d-block w-100" alt="...">
              </div>
            </div>
          </div>
        </div>

        <div class="sn-toc col col-md-4">
          <!-- <div class="caption d-block d-md-none text-center mb-5">
            <h1 class="title">Sarah & Nathan</h1>
            <h2 class="subtitle">We can’t wait to share our special day with you.</h2>
            <hr class="sn-line"/>
          </div> -->
          <h1 class="sn-loc title text-center">McKinney, Texas</h1>
          <h3 class="subtitle text-center">Saturday, September 24, 2022</h3>
          <h3 id="demo" class="sn-countdown subtitle text-center"></h3>
          <hr class="sn-line"/>

          <div class="sn-content">

            <h1 class="title pt-5 pb-3">Schedule & Location</h1>

            <p><b>There was a typo on the websilte that stated the incorrect time - the CEREMONY STARTS AT 5:30PM!!!</b></p>

            <p class="mb-0"><b>Schedule:</b> Ceremony at 5:30PM | Reception to follow</p>
            <p><b>Venue:</b> Stone Crest Venue in McKinney</p>

            <br />

            <h1 class="title pb-3">Our Story</h1>
              
            <p>We met in the summer of 2019 at our apartment complex pool and immediately bonded over a mutual love of running, art, and design. Shortly after, our relationship turned into a whirlwind of adventure when we moved to Big Sky, Montana where Sarah accepted a job as a ski instructor and Nathan was a "work from home cat dad." With Sarah's amazing design skills and Nate's ability to, well, not give up on a once failing business, things began flourish at Udundi (an ecommerce design/development agency). We now live in Austin, Texas and are striving to grow our business together with a small team of likeminded designers, developers and dreamers.</p>
            <p>The past 3 years have been filled with fun, laughter, love and travel. And we can’t wait for a lifetime of these things to come.</p>

            <h1 class="title pt-5 pb-3">Travel & Hotels</h1>
            <p>We have reserved hotel blocks at the following hotels in Plano and McKinney with discounted group rates.</p>
            <p>Use the link(s) below to get the group rate.</p>

            <p class="mb-0 fw-bold"><a target="_blank" href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1642006501160&key=GRP&app=resvlink">Mariott at Legacy Town Center &nearr;</a></p>
            <p class="mb-0 fw-bold">7121 Bishop Rd, Plano, TX 75024</p>
            <p class="mb-0">30 minute drive from venue</p>
            <!-- (Transportation option to and from the venue is planned) -->
            <p>20 minute drive from DFW airport</p>

            <p class="mb-0 fw-bold"><a target="_blank" href="https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=DALKNHT&arrivalDate=2022-09-23&departureDate=2022-09-25&room1NumAdults=1&cid=OM%2CWW%2CHILTONLINK%2CEN%2CDirectLink">Home2 Suites &nearr;</a></p>
            <p class="mb-0 fw-bold">2630 S Central Expy, McKinney, TX 75070</p>
            <p class="mb-0">15 min drive from venue</p>
            <p class="mb-0">30 min drive from DFW airpoort</p>

          </div>
          
          <img class="img-fluid py-5 my-5" src="../assets/swirl.png" />

          <a target="_blank" href="https://www.zola.com/registry/sarahandnathanseptember24" style="text-decoration: underline; color: #000;"><h3 class="subtitle fst-italic text-center fw-bold px-5">Registry &rsaquo;</h3></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {}
}

// var carouselFade = document.querySelector('#carouselFade')
// var carousel = new bootstrap.Carousel(carouselFade, {
//   interval: 2000
// })

// Set the date we're counting down to
var countDownDate = new Date("Sep 24, 2022 18:00:00").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Display the result in the element with id="demo"
  document.getElementById("demo").innerHTML = days + "d " + hours + "h "
  + minutes + "m " + seconds + "s ";

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("demo").innerHTML = "EXPIRED";
  }
}, 1000);
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Lato:wght@300&display=swap');

.navbar-brand {
  margin-right: 0;
}

.sn-intro {
  position: relative;
  top: 0;
  left: 0;
  padding-right: 0;
  height: 20rem;
  
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(to top, transparent 0, rgba(0,0,0,.4) 100%);
    z-index: 5;
  }

  @media (min-width: 768px) {
    .carousel {
      width: 100%;
      height: 100vh;
    }
  }

  .carousel-inner {
    height: 100%;

    .carousel-item {
      height: 360px;

      @media (min-width: 768px) {
        height: 100vh;
      }

      &:before {
        content: '';
        display: block;
        padding-top: 80%;
      }

      img {
        height: 100%;
        width: 100%;
        object-position: center;
        object-fit: cover;
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .caption {
    position: absolute;
    top: 5rem;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 5;

    .title {
      font-size: 68px;
      text-align: center;
      color: #fff;
    }

    .subtitle {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: #fff;
    }
  }

  .sn-bg {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.sn-toc {
  background-color: #f4f4f4;
  padding: 4rem 0;
}

@media (min-width: 768px) {
  .sn-intro {
    position: sticky;
    top: 0;
    left: 0;
    height: calc(100vh);
    overflow-y: hidden;
    padding-right: 12px;
  }

  .sn-toc {
    padding: 10rem 0;
  }
}

.title {
  color: #191f2a;
  font-family: 'Cormorant Garamond', serif;
  font-size: 31px;
  // font-weight: 700;
  font-style: normal;
  letter-spacing: 2px;
  line-height: normal;
  text-transform: uppercase;
}

.subtitle {
  color: #191f2a;
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.sn-loc {}

.sn-line {
  margin: 2rem 8rem 0 8rem;
}

.sn-content {
  padding: 0 2.5rem;
}
</style>
